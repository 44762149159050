import React from "react";
import CustomNavbar from "../Components/CustomNavbar";
import PageLeftSection from "../Components/PageLeftSection";
import PageRightSection from "../Components/PageRightSection";

const WhatWeDo = () => {
  return (
    <>
      <CustomNavbar bg="bg-dark" />
      <div className="bg-image-container top-image px-5 position-relative vh-100 d-flex align-items-center justify-content-center">
        <img
          className="bg-image position-absolute w-100 h-100"
          src="/Images/image1.png"
          alt="Background Image"
        />
        <div className="bg-overlay position-absolute w-100 h-100 " />
        <div className="content-container d-flex flex-column align-items-center justify-content-center">
          <h1 className="bg-heading position-relative text-white text-center">
            What We Do
          </h1>
          <p className="text-center">
            We have a strong commitment to technological development,
            digitalization, innovation and the ongoing updating of our
            knowledge.
          </p>
        </div>
      </div>

      <div
        style={{ borderTop: "2px solid yellow" }}
        className="bg-image-container px-5 position-relative vh-100 d-flex align-items-center justify-content-center flex-wrap"
      >
        <img
          className="bg-image position-absolute w-100 h-100"
          src="/Images/image3.png"
          alt="Background Image"
        />
        <div className="bg-overlay position-absolute w-100 h-100 " />


  <div className="row col-12 text-center">
    <div className="col-md-6 col-lg-3 my-1 d-flex">
    <div className="col-12 bg-main p-4 shadow-box flex-fill d-flex flex-column zoom-effect">
        <h2>Strategic consulting</h2>
        <p>
          Our team of consultants has a wide experience and knowledge in
          the technological area, offering high quality advice, defining
          the solutions that best suit the client's needs, as well as
          defining how to reach them in the best possible way.{" "}
        </p>
      </div>
    </div>
    <div className="col-md-6 col-lg-3 my-1 d-flex">
    <div className="col-12 bg-main p-4 shadow-box flex-fill d-flex flex-column zoom-effect">
        <h2>Outsourcing</h2>
        <p>
          Our outsourcing service is aimed at all those companies that
          want to outsource their projects, as well as different areas and
          departments within the organization, ensuring that the
          technological needs of their company are covered at all times
          and avoiding costs in personnel and technology.{" "}
        </p>
      </div>
    </div>
    <div className="col-md-6 col-lg-3 my-1 d-flex">
    <div className="col-12 bg-main p-4 shadow-box flex-fill d-flex flex-column zoom-effect">
        <h2>Design and Development</h2>
        <p>
          We design and develop solutions that are based on innovation and
          new technologies to respond exactly to the needs of our
          customers, adapting to the different environments in which we
          operate.
        </p>
      </div>
    </div>
    <div className="col-md-6 col-lg-3 my-1 d-flex">
    <div className="col-12 bg-main p-4 shadow-box flex-fill d-flex flex-column zoom-effect">
        <h2>Technical Support and Maintenance</h2>
        <p>We provide technical assistance to all our customers who need it, solving any incident and analyzing the performance and optimization of processes.</p>
      </div>
    </div>
  </div>
</div>
      {/* <PageLeftSection
        image="/Images/business_man.png"
        heading="Strategic consulting"
        text="Our team of consultants has a wide experience and knowledge in the technological area, offering high quality advice, defining the solutions that best suit the client's needs, as well as defining how to reach them in the best possible way. "
      />
      <PageRightSection
        image="Images/00011.png"
        heading="Outsourcing"
        text="Our outsourcing service is aimed at all those companies that want to outsource their projects, as well as different areas and departments within the organization, ensuring that the technological needs of their company are covered at all times and avoiding costs in personnel and technology. "
      />
      <PageLeftSection
        image="/Images/image2.png"
        heading="Design and Development"
        text="We design and develop solutions that are based on innovation and new technologies to respond exactly to the needs of our customers, adapting to the different environments in which we operate."
      />
      <PageRightSection
        image="Images/image4.png"
        heading="Technical Support and Maintenance."
        text="We provide technical assistance to all our customers who need it, solving any incident and analyzing the performance and optimization of processes.
"
      /> */}
    </>
  );
};

export default WhatWeDo;
