import React from "react";
import CustomNavbar from "../Components/CustomNavbar";
import PageLeftSection from "../Components/PageLeftSection";
import PageRightSection from "../Components/PageRightSection";
import Slider from "react-slick";
const Services = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => <div className="slider-dot my-3" />,
    appendDots: (dots) => (
      <div className="slider-dots-container  my-3">{dots}</div>
    ),
    autoplay: false,
    autoplaySpeed: 4000, // Adjust this value to control the speed of auto-scrolling (in milliseconds)
  };

  const slides = [
    {
      image: "Images/image5.png",
      heading: "Process design and <br> re-engineering",
      text: "We review and improve our clients' internal processes with the aim of increasing their efficiency and productivity. Our development team uses methodologies such as Lean, Six Sigma, or BPMN to identify areas for improvement and design more efficient processes. Additionally, we can develop tools and systems to automate manual processes, reduce errors, and improve the quality of work. ",
    },

    {
      image: "/Images/image8.png",
      heading: "Analysis and design of solutions",
      text: "We work closely with companies to understand their needs and design customized solutions. Our team identifies functional and non-functional requirements, designs software architecture, and selects appropriate technologies to ensure that the solution is effective and meets our clients' needs. We also provide testing and evaluations to ensure that the solution meets quality standards.",
    },
    {
      image: "Images/image3.png",
      heading: "Migration projects",
      text: "We help companies migrate systems and applications to new platforms or more modern technologies. We evaluate current systems, plan the migration, and implement it to ensure its success. We also provide support services to ensure that the migration is carried out without any issues.",
    },
    {
      image: "/Images/image4.png",
      heading: "Application development",
      text: "We focus on the development of customized applications for businesses. We work closely with our clients to understand their needs and develop solutions that meet their specific requirements. We define system requirements, design software architecture, select appropriate technologies, and implement the solution. We also provide testing and evaluations to ensure that the solution meets quality standards.",
    },
    {
      image: "Images/image2.png",
      heading: "Contingency and business continuity plans",
      text: "We create contingency and business continuity plans in case of emergency situations. We work closely with client companies to develop plans that enable them to maintain their operations in crisis situations, such as natural disasters, energy supply interruptions, or cyberattacks. This includes identifying risks and vulnerabilities, defining recovery processes, implementing contingency solutions, and conducting simulations to evaluate the effectiveness of the plan.",
    },
    {
      image: "/Images/image1.png",
      heading: "Custom software development",
      text: "We develop customized software solutions for client companies, designed to meet their specific needs. Our software development team works closely with client companies to understand their requirements and objectives, and then designs a software solution that fits their needs. We have a highly trained and experienced team of developers who can work in different programming languages and technology platforms. Additionally, we have processes and tools in place to ensure software quality and efficient project management.",
    },
    {
      image: "Images/image6.png",
      heading: "Maintenance and specialized support",
      text: "<small>We focus on the maintenance and support of the systems and applications developed by our company. We provide continuous maintenance and support services to ensure the proper functioning of systems and applications. Additionally, we offer specialized support to resolve specific issues that may arise. Overall, our goal is to ensure customer satisfaction and provide customized solutions that meet their specific needs.Maintenance and support are crucial to ensuring the functionality and continuous availability of enterprise systems and applications.Companies may opt for long-term maintenance and support services to ensure that their systems and applications are always up-to-date and running,and to reduce downtime that can be costly for their business.Additionally,specialized support services can provide a quick and efficient solution to resolve any technical issue that may arise.</small>",
    },
  ];
  return (
    <>
      <CustomNavbar bg="bg-dark" />
      <div className="bg-image-container top-image px-5 position-relative vh-100 d-flex align-items-center justify-content-center">
        <img
          className="bg-image position-absolute w-100 h-100"
          src="/Images/image5.png"
          alt="Background Image"
        />
        <div className="bg-overlay position-absolute w-100 h-100 " />
        <div className="content-container d-flex flex-column align-items-center justify-content-center">
          <h1 className="bg-heading position-relative text-white text-center">
            Services and Solutions
          </h1>
          <p className="text-center">
            We offer our portfolio of services and solutions for a wide variety
            of needs, from the integral management of the technological
            processes of your business, to customized solutions for each
            specific case.
          </p>
        </div>
      </div>

      <div
        style={{ borderTop: "2px solid yellow" }}
        className="bg-image-container px-5 position-relative vh-100 d-flex align-items-center justify-content-center flex-wrap"
      >
        <img
          className="bg-image position-absolute w-100 h-100"
          src="/Images/image3.png"
          alt="Background Image"
        />
        <div className="bg-overlay position-absolute w-100 h-100 " />
        <div className="slider-container container border bg-main">
          <Slider {...sliderSettings}>
            {slides.map((item, index) => (
              <div
                key={index}
                className="slide d-flex row align-items-center"
                style={{ minHeight: "100%" }}
              >
                <div className="slide-image-container col-md-6">
                  <img
                    className="slide-image"
                    src={item.image}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
                <div className="slide-content-container  col-md-6">
                  <h1
                    className="w-100 text-center"
                    dangerouslySetInnerHTML={{ __html: item.heading }}
                  />{" "}
                  <p
                    className=""
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Services;
