import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer bg-main px-5">
      <div className="footer-left d-flex align-items-center">
        <img
          src="Images/white-logo.png"
          alt="Logo"
          className="footer-logo me-4"
        />
        <div className="pt-2">
          <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-facebook-f"></i>
          </a>
          <a
            href="https://www.instagram.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-instagram"></i>
          </a>
          <a
            href="https://www.linkedin.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
      <div className="footer-right">
        <Link to="#terms-and-conditions">Terms and Conditions</Link> |
        <Link to="/privacy-policy">Privacy Policy</Link> |
        <Link to="/legal-notice">Legal Notice</Link> |
        <Link to="/cookie-policy">Cookies Policy</Link>
      </div>
    </footer>
  );
};

export default Footer;
