import React,{ useState, useEffect } from "react";
import Hero from "../Components/Hero";
import LeftText from "../Components/LeftText";
import RightText from "../Components/RightText";
import ContactUs from "../Components/ContactUs";
import HomePageNavbar from "../Components/HomePageNavbar";

import ScrollIndicator from "../Components/ScrollIndicator";
export default function Home() {
  const [imageIndex, setImageIndex] = useState(0);
  const images = [
    "/images/portada-copia-scaled.jpg",
    "/images/portada-copia2-scaled.jpg",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [images.length]);



  useEffect(() => {
    const updateActiveMenuItem = () => {
      const navLinks = document.querySelectorAll(".nav-link");
      const sections = ["home", "about", "what_we_do", "service", "contact"];

      let currentActiveIndex = 0;

      sections.forEach((sectionId, index) => {
        const section = document.getElementById(sectionId);
        const rect = section.getBoundingClientRect();

        if (rect.top <= 0 && rect.bottom >= 0) {
          currentActiveIndex = index;
        }
      });

      navLinks.forEach((link, index) => {
        if (index === currentActiveIndex) {
          link.classList.add("active");
        } else {
          link.classList.remove("active");
        }
      });
    };

    window.addEventListener("scroll", updateActiveMenuItem);

    return () => {
      window.removeEventListener("scroll", updateActiveMenuItem);
    };
  }, []);
  return (
    <>
        <HomePageNavbar bg="bg-transparent" />
<div id="home"></div>
      <div  className="stack__card">
      <div
      className="container-fluid px-5"
      style={{
        backgroundImage: `url(${images[imageIndex]})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        transition: "background-image 1s ease-in-out",
        backgroundBlendMode: "overlay",
        objectFit: "cover",
      }}
    >

      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
        }}
      >
        <div
          className="text-left col-md-5"
          style={{
            padding: "40px",
            borderTop: "2px solid white",
          }}
        >
          <h1 style={{ color: "white", fontSize: "2rem" }}>
            Technology Solutions to take your business to the next level
          </h1>
          <p
            style={{
              color: "white",
              marginTop: "20px",
              fontSize: "1rem",
              letterSpacing: "1px",
              color: "rgb(255,255,255,0.6)",
            }}
          >
           Harness the power of technology to drive
your company's growth.
          </p>
        </div>
      </div>
    </div>
      </div>

      <div id="about" className="stack__card">
        <LeftText
          heading="About Us"
          image="/Images/00009.png"
          link="/about"
          text="At Halfware Software Development, we're passionate about technological innovation and providing ﬁrst-class outsourcing services to meet the unique needs of our clients. Our team of highly qualiﬁed IT and telecommunications engineers have over a decade of experience in delivering exceptional results in a variety of industries."
        />
      </div>
      <div id="what_we_do" className="stack__card">
        <RightText
          heading="What we do"
          image="/Images/00011.png"
          link="what_we_do"
          text="We have a strong commitment to technological development, digitalization, innovation and the ongoing updating of our knowledge.

        <ul>
        <li>Strategic consulting</li>
        <li>Outsourcing</li>
        <li>Design and Development</li>
        <li>Technical Support and Maintenance</li>

        </ul>"
        />
      </div>
      <div id="service" className="stack__card">
        <LeftText
          heading="Services and Solutions"
          image="/Images/image1.png"
          link="service"
          text="We offer our portfolio of services and solutions for a wide variety of needs, from the integral management of the technological processes of your business, to customized solutions for each specific case.
<ul>
<li>Process design and re-engineering</li>
<li>	Analysis and design of solutions</li>
<li>	Migration projects</li>
<li>	Application development</li>
<li>	Contingency and business continuity plans</li>
<li>	Custom software development</li>
<li>	Maintenance and specialized support</li>
<ul>
"
        />
      </div>
<ScrollIndicator/>
    </>
  );
}
