import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="cookie-policy-container">
      <div className="image-container" >
      <nav style={{zIndex:'999999999',position:'absolute'}} className="navbar navbar-expand-lg p-3  bg-transparent">
          <div className="container-fluid bg-transparent">
            <Link className="navbar-brand" to="/">
              <img src="/Images/white-logo.png" alt="Logo" width="130" />
            </Link>
          </div>
        </nav>
        <img
          className="image-container-img"
          src="/Images/privacy.jpeg"
          alt="Cookie Policy"
        />
        <div className="overlay"></div>

        <h2 className="cookie-policy-heading">
          <span>Privacy Policy of Halfware </span>
          <br />
          <span> Software Development</span>
        </h2>
      </div>
      <div className="bg-white w-100 p-5 text-dark">
      <h1 className="text-dark"></h1>
  <b className="text-dark">At HALFWARE SOFTWARE DEVELOPMENT we value your privacy and are committed to protecting your personal information. This Privacy Policy describes how we collect, use, and share the personal information we obtain through our website and online services. By using our website or services, you agree to the terms of this Privacy Policy.</b>

  <h2 className="text-dark">Collection of Personal Information</h2>
  <p className="text-dark">We collect personal information that you directly provide through our website or services, such as your name, email address, mailing address, phone number, and other contact information. We may also collect information about your use of our website and services, including your IP address, browser type, operating system, pages visited, location information, and other log information.</p>

  <h2 className="text-dark">Use of Personal Information</h2>
  <p className="text-dark">We use your personal information to provide you with our products and services, respond to your inquiries, personalize your user experience, administer your account, and send you promotional communications. We may also use your personal information to improve our products and services and for internal purposes, such as data analytics and compliance with our legal obligations.</p>

  <h2 className="text-dark">Disclosure of Personal Information</h2>
  <p className="text-dark">We may share your personal information with third parties who assist us in providing our products and services, including hosting service providers, payment service providers, and marketing service providers. We may also share your personal information when necessary to comply with applicable law, respond to a court order, or protect our legal rights.</p>

  <h2 className="text-dark">Security of Personal Information</h2>
  <p className="text-dark">We are committed to protecting the security of your personal information and have implemented reasonable technical and organizational security measures to protect it against unauthorized access, alteration, disclosure, or misuse.</p>

  <h2 className="text-dark">Retention of Personal Information</h2>
  <p className="text-dark">We retain your personal information for as long as necessary to fulfill the purposes for which it was collected or to comply with our legal obligations, unless a longer retention period is required or permitted by law.</p>

  <h2 className="text-dark">Privacy Rights</h2>
  <p className="text-dark">You have certain privacy rights in relation to your personal information, such as the right to access, rectify, and delete your personal information. You also have the right to object to the processing of your personal information in certain circumstances. If you wish to exercise any of these rights, please contact us using the contact information provided below.</p>

  <h2 className="text-dark">Changes to the Privacy Policy</h2>
  <p className="text-dark">We may update this Privacy Policy from time to time to reflect changes in our privacy practices. We recommend that you periodically review this Privacy Policy to stay informed of any significant changes. If we make material changes to this Privacy Policy, we will notify you by posting an updated version on our website.</p>

  <h2 className="text-dark">Contact</h2>
  <p className="text-dark">If you have any questions or concerns about this Privacy Policy, please contact us through:</p>
  <ul>
    <li className="text-dark"><b>Office Address:</b> HALFWARE SOFTWARE DEVELOPMENT ALSUAIDI Building, Al Mararr 1-11, Dubai, UAE</li>
<li className="text-dark"><b>P.O. Box 25691</b></li>
<li className="text-dark"><b>Mail:</b> info@halfware.io</li>

  </ul>
  <p className="text-dark"><b>Effective Date:</b> May 21, 2023.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
