import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function HomePageNavbar(props) {

  return (
    <nav
    className="navbar navbar-expand-lg p-3 navbar-light bg-transparent fixed-navbar"
  >
      {" "}
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src="/Images/white-logo.png" alt="Logo" width="130" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link text-light" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-light" to="/about">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-light" to="/what_we_do">
                What We Do
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-light" to="/service">
                Services and Solutions
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default HomePageNavbar;
