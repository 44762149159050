import "./App.css";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import WhatWeDo from "./Pages/WhatWeDo";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ContactUs from "./Components/ContactUs";
import ScrollToTop from "./Components/ScrollToTop";
import Services from "./Pages/Services";
import HomePageNavbar from "./Components/HomePageNavbar";
import { useEffect } from "react";
import Footer from "./Components/Footer";
import CookiePolicy from "./Pages/CookiePolicy";
import LegalNotice from "./Pages/LegalNotice";
import PrivacyPolicy from "./Pages/PricavyPolicy";
import CookieConsent from "./Components/CookieConsent";
function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/what_we_do" element={<WhatWeDo />} />
          <Route path="/service" element={<Services />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/legal-notice" element={<LegalNotice />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <div className="stack__card row">
          <ContactUs />
          <Footer />
        </div>
        <CookieConsent/>
      </Router>
    </div>
  );
}

export default App;
