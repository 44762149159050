import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function CustomNavbar(props) {
  const location = useLocation();
  const path = location.pathname;

  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop;
      const scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        document.body.scrollHeight;
      const clientHeight =
        document.documentElement.clientHeight || window.innerHeight;
      const scrolledToBottom =
        Math.ceil(scrollTop + clientHeight) >= scrollHeight - 500;

      setIsBottom(scrolledToBottom);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isActive = (menuPath) => {
    if (isBottom) {
      return "";
    }
    return path === menuPath ? "active" : "";
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <nav class="navbar navbar-expand-lg p-3 navbar-light bg-dark fixed-navbar ">
      <div class="container-fluid ">
        <Link class="navbar-brand" to="/">
          <img src="/Images/white-logo.png" alt="Logo" width="130" />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <li className={`nav-item ${isActive("/")}`}>
              <Link
                className="nav-link text-light"
                to="/"
                onClick={scrollToTop}
              >
                Home
              </Link>
            </li>
            <li className={`nav-item ${isActive("/about")}`}>
              <Link
                className="nav-link text-light"
                to="/about"
                onClick={scrollToTop}
              >
                About Us
              </Link>
            </li>
            <li className={`nav-item ${isActive("/what_we_do")}`}>
              <Link
                className="nav-link text-light"
                to="/what_we_do"
                onClick={scrollToTop}
              >
                What We Do
              </Link>
            </li>
            <li className={`nav-item ${isActive("/service")}`}>
              <Link
                className="nav-link text-light"
                to="/service"
                onClick={scrollToTop}
              >
                Services and Solutions
              </Link>
            </li>
            <li className={`nav-item ${isBottom ? "active" : ""}`}>
              <a
                className="nav-link text-light"
                onClick={() => scrollToSection("contact")}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default CustomNavbar;
