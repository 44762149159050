import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const CookieConsent = () => {
  const [showModal, setShowModal] = useState(false);
  const hasAccepted = localStorage.getItem('hasAcceptedCookieConsent');
  useEffect(() => {
    if (!hasAccepted) {
      setShowModal(true);

    }
  }, []);

  const acceptCookies = () => {
    setShowModal(false);
    localStorage.setItem('hasAcceptedCookieConsent','true');
  };

  const declineCookies = () => {
    setShowModal(false);
  };

  return (
    showModal && (
      <div className="cookie-consent-modal">
        <div className="modal-content">
          <p className='text-dark'>
            We use cookies to enhance your experience on our website. Please
            review our <Link to="/cookie-policy">cookie policy</Link> for more
            information. Do you accept our use of cookies?
          </p>
          <div className="modal-buttons">
            <button className='btn btn-success me-1' onClick={acceptCookies}>Accept</button>
            <button className="btn btn-danger ms-1"onClick={declineCookies}>Cancel</button>
          </div>
        </div>
      </div>
    )
  );
};

export default CookieConsent;
