import React from "react";
import CustomNavbar from "../Components/CustomNavbar";
import PageLeftSection from "../Components/PageLeftSection";
import PageRightSection from "../Components/PageRightSection";

const AboutUs = () => {
  return (
    <>
      <CustomNavbar bg="bg-dark" />
      <div className="bg-image-container top-image px-5 position-relative vh-100 d-flex align-items-center justify-content-center">
        <img
          className="bg-image position-absolute w-100 h-100"
          src="/Images/00009.png"
          alt="Background Image"
        />
        <div className="bg-overlay position-absolute w-100 h-100 " />
        <div className="content-container d-flex flex-column align-items-center justify-content-center">
          <h1 className="bg-heading position-relative text-white text-center">
            About Us
          </h1>
          <p className="text-center">
            At Halfware Software Development, we're passionate about
            technological innovation and providing ﬁrst-class outsourcing
            services to meet the unique needs of our clients. Our team of highly
            qualiﬁed IT and telecommunications engineers have over a decade of
            experience in delivering exceptional results in a variety of
            industries.
          </p>
        </div>
      </div>

      <div
        style={{ borderTop: "2px solid yellow" }}
        className="bg-image-container px-5 position-relative vh-100 d-flex align-items-center justify-content-center flex-wrap"
      >
        <img
          className="bg-image position-absolute w-100 h-100"
          src="/Images/image10.png"
          alt="Background Image"
        />
        <div className="bg-overlay position-absolute w-100 h-100 " />
        <div className="content-container row d-flex flex-column align-items-center justify-content-center ">
          <h1 className="bg-heading position-relative text-white text-center">
            Mission, Vision, and Values
          </h1>
          <p className="text-center">
            Harnessing our knowledge and proficiency, we furnish outstanding
            service and assistance, dedicated to achieving exceptional outcomes.
          </p>
        </div>
        <div className="row col-12">
          <div className="col-md-4 circle-start">
            <div className="col-12 p-2 text-center">
              <p>
                {" "}
                Our mission is to provide cutting-edge technological solutions
                that improve the efﬁciency, proﬁtability, and productivity of
                businesses and organizations. We are dedicated to providing high
                quality services that help you overcome your challenges and
                achieve your business goals.
              </p>
            </div>
          </div>
          <div className="col-md-4 circle-bottom">
            <div className="col-12 p-2 text-center">
              <p>
                {" "}
                Our vision is to become leaders in the provision of innovative
                technological solutions that meet your needs. We aspire to be
                recognized for our excellence in service, our ability to solve
                complex problems, and our commitment to innovation.
              </p>
            </div>
          </div>
          <div className="col-md-4 circle-end">
            <div className="col-12 p-2 text-center">
              <p>
                {" "}
                Our work is guided by a set of fundamental values, including
                innovation, commitment to quality, excellence in customer
                service, integrity, and business ethics. These values govern
                everything we do and ensure that we provide the best possible
                service.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ borderTop: "2px solid yellow" }}
        className="bg-image-container px-5 position-relative vh-100 d-flex align-items-center justify-content-center flex-wrap"
      >
        <img
          className="bg-image position-absolute w-100 h-100"
          src="/Images/image13.png"
          alt="Background Image"
        />
        <div className="bg-overlay position-absolute w-100 h-100 " />

        <div className="row col-12">
          <div className="col-md-5">
            <div className="col-12 p-2 text-right">
              <h1>Target Audience</h1>
              <p>
                We work with companies and organizations from various sectors
                that seek to improve their efﬁciency and productivity through
                technological innovation. Our target audience ranges from small
                and medium-sized enterprises to large corporations, as well as
                government and non-proﬁt organizations seeking innovative and
                high-quality technological solutions. We're here to help you
                succeed, no matter your industry or business size.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <PageLeftSection
        image="/Images/business_man.png"
        text="At Halfware Software Development, we're passionate about technological innovation and providing ﬁrst-class outsourcing services to meet the unique needs of our clients. Our team of highly qualiﬁed IT and telecommunications engineers have over a decade of experience in delivering exceptional results in a variety of industries.<br><br>
        Our extensive experience allows us to optimize and improve processes, ensuring rapid adaptation to the unique needs of our customers. We prioritize building strong relationships with our patrons, placing trust and transparency at the forefront of all our interactions. Leveraging our experience and expertise, we provide excellent service and support, committed to delivering exceptional results.<br><br>
        Harnessing our knowledge and proficiency, we furnish outstanding service and assistance, dedicated to achieving exceptional outcomes.
        "
      />
      <PageRightSection
        image="Images/image1.png"
        heading="Mission"
        text="Our mission is to provide cutting-edge technological solutions that improve the efﬁciency, proﬁtability, and productivity of businesses and organizations. We are dedicated to providing high quality services that help you overcome your challenges and achieve your business goals. "
      />
      <PageLeftSection
        image="/Images/image2.png"
        heading="Vision"
        text="Our vision is to become leaders in the provision of innovative technological solutions that meet your needs. We aspire to be recognized for our excellence in service, our ability to solve complex problems, and our commitment to innovation."
      />
      <PageRightSection
        image="Images/image7.png"
        heading="Target Audience"
        text="We work with companies and organizations from various sectors that seek to improve their efﬁciency and productivity through technological innovation.<br><br>
Our target audience ranges from small and medium-sized enterprises to large corporations, as well as government and non-proﬁt organizations seeking innovative and high-quality technological solutions. We're here to help you succeed, no matter your industry or business size.
"
      /> */}
    </>
  );
};

export default AboutUs;
