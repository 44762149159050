import React, { useEffect, useState } from "react";

const ScrollIndicator = () => {
  const [upArrowVisible, setUpArrowVisible] = useState(false);
  const [downArrowVisible, setDownArrowVisible] = useState(true);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (scrollPosition > 400) {
      setUpArrowVisible(true);
    } else {
      setUpArrowVisible(false);
    }

    if (scrollPosition + windowHeight >= documentHeight-400) {
      setDownArrowVisible(false);
    } else {
      setDownArrowVisible(true);
    }
  };


  const scrollUp = () => {
    const windowHeight = window.innerHeight;
    window.scrollBy({ top: -windowHeight, behavior: "smooth" });
  };

  const scrollDown = () => {
    const windowHeight = window.innerHeight;
    window.scrollBy({ top: windowHeight, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="scroll-indicator">
      {upArrowVisible && (
        <div className="arrow arrow-up zoom-in-out" onClick={scrollUp}>
          <i class="fas fa-chevron-up"></i>
        </div>
      )}
      <div className="mouse-icon"></div>
      {downArrowVisible &&(
      <div className="arrow arrow-down zoom-in-out" onClick={scrollDown}>
        <i class="fas fa-chevron-down"></i>
      </div>  )}
    </div>
  );
};

export default ScrollIndicator;
