import React, { useState, useEffect } from "react";
import HomePageNavbar from "./HomePageNavbar";

function Hero() {

  return (
<></>
  );
}

export default Hero;
