import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const CookiePolicy = () => {
  return (
    <div className="cookie-policy-container">
    <div className="image-container">
      <nav style={{zIndex:'999999999',position:'absolute'}} className="navbar navbar-expand-lg p-3  bg-transparent">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src="/Images/white-logo.png" alt="Logo" width="130" />
          </Link>
        </div>
      </nav>
      <img
        className="image-container-img"
        src="/Images/legal.webp"
        alt="Cookie Policy"
      />
      <div className="overlay"></div>

      <h2 className="cookie-policy-heading">
        <span>Legal Notice of Halfware </span>
        <br />
        <span> Software Development</span>
      </h2>
    </div>
    <div className="bg-white w-100 p-5 text-dark">
    <b className="text-dark">Welcome to HALFWARE SOFTWARE DEVELOPMENT, an IT solutions and services provider based in Dubai. By using our services and website, you agree to these terms and conditions of use. If you do not agree to these terms, you may not use our services.</b>

  <h2 className="text-dark">Company Information</h2>
  <p className="text-dark">HALFWARE SOFTWARE DEVELOPMENT is a company with its registered office at ALSUAIDI Building, Al Mararr 1-11, P.O. Box 25691, Dubai, United Arab Emirates. It is registered with the Department of Economic Development of Dubai (DED) and has the necessary permits and licenses to operate (license No. 1140952).</p>

  <h2 className="text-dark">Intellectual Property</h2>
  <ul>
      <li className="text-dark">All intellectual property rights related to our website and services, including but not limited to copyright, trademarks, and patents, are the exclusive property of HALFWARE SOFTWARE DEVELOPMENT and are protected by applicable intellectual property laws.</li>
      <li className="text-dark">Reproduction, distribution, or modification of any content on our website or services without our prior written consent is not allowed.</li>
  </ul>

  <h2 className="text-dark">Limitation of Liability</h2>
  <ul>
      <li className="text-dark">We will not be liable for any loss or damage arising from the use of our website or services, including but not limited to any loss of profits, loss of data, loss of business, or interruption of business activity.</li>
      <li className="text-dark">We strive to provide accurate and up-to-date information on our website and services, but we do not guarantee the accuracy, completeness, or timeliness of such information.</li>
      <li className="text-dark">We reserve the right to modify or remove any content or service on our website without notice.</li>
  </ul>

  <h2 className="text-dark">Links to Third-Party Websites</h2>
  <p className="text-dark">Our website may contain links to third-party websites that are not under our control. We are not responsible for the content or privacy practices of these websites.</p>

  <h2 className="text-dark">Applicable Law and Jurisdiction</h2>
  <p className="text-dark">These terms and conditions shall be governed and interpreted in accordance with the laws of the United Arab Emirates, and any dispute arising in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Dubai.</p>

  <b className="text-dark">If you have any questions or concerns about our terms and conditions, please feel free to contact us at <a href="mailto:info@halfware.io" className="text-dark">info@halfware.io</a>.</b>

    </div>
  </div>
  );
};

export default CookiePolicy;
