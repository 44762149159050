import React from "react";
import { Link } from "react-router-dom";

const LeftText = (props) => {
  return (
    <div className="container-fluid p-0">
      <div className="row no-gutters vh-100 align-items-stretch">
        <div className="col-lg-5 p-5 d-flex align-items-center">
          <div className="text-container">
            <h1>{props.heading}</h1>
            <p
              style={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{ __html: props.text }}
            />
            {props.link ? (
              <div className="w-100 text-center">
                <Link to={props.link}>
                  <button className="btn bg-light rounded-0 p-3 readmore-btn">
                    Read more
                  </button>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-lg-7">
          <img
            className="img-fluid d-lg-block d-none"
            src={props.image}
            alt="My Image"
          />
          <img
            className="img-fluid d-lg-none"
            src={props.image}
            alt="My Image"
          />
        </div>
      </div>
    </div>
  );
};

export default LeftText;
