import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import emailjs from "emailjs-com";

const ContactUs = () => {
  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    speed: 500,
  });

  useEffect(() => {
    const updateSettings = () => {
      if (window.innerWidth < 786) {
        setSettings({
          ...settings,
          slidesToShow: 1,
          slidesToScroll: 1,
        });
      } else {
        setSettings({
          ...settings,
          slidesToShow: 2,
          slidesToScroll: 2,
        });
      }
    };

    updateSettings();

    window.addEventListener("resize", updateSettings);
    return () => window.removeEventListener("resize", updateSettings);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    const name = e.target.elements.name.value;
    const email = e.target.elements.email.value;
    const message = e.target.elements.message.value;

    // Validate form input
    if (!name || !email || !message) {
      alert("All fields are required");
      return;
    }

    // Add regular expression to validate email format
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    if (!email.match(emailRegex)) {
      alert("Invalid email format");
      return;
    }

    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "YOUR_PUBLIC_KEY"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send message");
        }
      );
  };

  return (
    <div
      id="contact"
      className="container-fluid"
      style={{ backgroundColor: "#080c14" }}
    >
      <div className="row no-gutters min-vh-100 d-flex align-items-center px-sm-5 ">
        <div className="col-lg-6 p-5">
          <div className="contact-info">
            <h4 className="mb-4">Contact Us</h4>
            <h1>
              <b>
                Get In <span style={{ color: "#0c2028" }}>Touch</span>{" "}
              </b>
            </h1>
            <p>
              Thank you for visiting our website! We are thrilled to have the
              opportunity to connect with you and learn more about how we can
              help your business grow through our software solutions.
            </p>
            <div className="office-address row">
              <h2 className="col-12">
                <i className="fas fa-map-marker-alt"></i>Office Address
              </h2>
              <p className="col-12">
              HALFWARE SOFTWARE DEVELOPMENT. ALSUAIDI Building, Al Mararr 1-11, Dubai, UAE

              </p>
            </div>
            <div className="email row">
              <h2 className="col-12">
                <i className="fas fa-envelope"></i> Mail Us
              </h2>
              <p className="col-12">info@halfware.io</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-5 text-center">
          <div className="pb-5">
            <img height={47} src="/Images/white-logo.png" alt="Logo 1" />
          </div>
          <div className="contact-form">
            <form onSubmit={sendEmail}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control rounded-0"
                  placeholder="Name"
                  name="name"
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control rounded-0"
                  placeholder="Email"
                  name="email"
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control rounded-0"
                  rows={4}
                  name="message"
                  placeholder="Message"
                ></textarea>
              </div>
              <button type="submit" className="btn col-12 rounded-0 btn-light">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ContactUs;
