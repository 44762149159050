import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const CookiePolicy = () => {
  return (
    <div className="cookie-policy-container">
      <div className="image-container">
      <nav style={{zIndex:'999999999',position:'absolute'}} className="navbar navbar-expand-lg p-3  bg-transparent">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img src="/Images/white-logo.png" alt="Logo" width="130" />
            </Link>
          </div>
        </nav>
        <img
          className="image-container-img"
          src="/Images/cookie.jpeg"
          alt="Cookie Policy"
        />
        <div className="overlay"></div>

        <h2 className="cookie-policy-heading">
          <span>Cookie Policy of Halfware </span>
          <br />
          <span> Software Development</span>
        </h2>
      </div>
      <div className="bg-white w-100 p-5 text-dark">
        <h1 className="text-dark">Introduction</h1>
        <p className="text-dark">
          This cookie policy applies to the website of HALFWARE SOFTWARE DEVELOPMENT, an IT
          services and solutions provider based in Dubai. We use cookies and
          similar technologies on our website to enhance the user experience and
          to understand how our website is used.
        </p>

        <h2 className="text-dark">What are cookies?</h2>
        <p className="text-dark">
          Cookies are small text files that are stored on your computer or
          mobile device when you visit a website. Cookies are used to enhance
          the user experience and to provide website owners with information
          about how their website is used.
        </p>

        <h2 className="text-dark">What types of cookies do we use?</h2>
        <p className="text-dark">
          We use the following types of cookies on our website:
        </p>
        <ul className="text-dark">
          <li>
            <strong>Essential cookies:</strong> These cookies are necessary for
            the basic functioning of our website and cannot be turned off in our
            systems.
          </li>
          <li>
            <strong>Performance cookies:</strong> These cookies allow us to
            collect information about how our website is used, such as the pages
            you visit most frequently and any error messages you may receive. We
            use this information to improve the performance and user experience
            of our website.
          </li>
          <li>
            <strong>Functionality cookies:</strong> These cookies allow us to
            remember choices you have made on our website, such as your
            preferred language, and to provide a personalized experience.
          </li>
          <li>
            <strong>Advertising cookies:</strong> These cookies are used to
            deliver more relevant ads to you and your interests. They are also
            used to limit the number of times you see an ad and to measure the
            effectiveness of an advertising campaign.
          </li>
        </ul>

        <h2 className="text-dark">How can you control cookies?</h2>
        <p className="text-dark">
          You can control and manage cookies on your computer or mobile device
          through your browser settings. Please note that disabling cookies may
          affect the functionality of our website.
        </p>

        <h2 className="text-dark">How can you get more information?</h2>
        <p className="text-dark">
          If you have any questions or concerns about our cookie policy, please
          feel free to contact us at{" "}
          <a href="mailto:info@halfware.io">info@halfware.io</a>.
        </p>

        <p className="text-dark">
          By using our website, you consent to the use of cookies in accordance
          with this cookie policy. If you do not agree to the use of cookies,
          you should disable them in your browser settings or not use our
          website.
        </p>
      </div>
    </div>
  );
};

export default CookiePolicy;
